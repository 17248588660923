import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { fontRobotoCondensed, transientOptions } from "@product/scmp-sdk";

import { Avatar } from "~/components/avatar";
import IconAvatar from "~/components/home/header/header-icon/avatar.svg";
type AvatarProps = {
  $isLoggedIn: boolean;
};

type DashboardWidgetProps = AvatarProps;

export const Container = styled("div", {
  ...transientOptions,
})<DashboardWidgetProps>`
  ${props =>
    !props.$isLoggedIn &&
    css`
      .MuiBadge-badge {
        display: none;
      }
    `}
`;

export const StyledAvatar = styled(Avatar, { ...transientOptions })<AvatarProps>`
  ${props =>
    css`
      display: ${props.$isLoggedIn ? "block" : "none"};
      ${props.theme.breakpoints.up("tablet")} {
        display: block;

        ${props.$isLoggedIn
          ? css`
              & {
                inline-size: 46px;
                block-size: 46px;
              }
              & .sa-avatar-widget__image {
                inline-size: 42px;
                block-size: 42px;
              }
            `
          : null}
      }
    `}
`;

export const SignInText = styled.div`
  color: #4585ff;
  font-size: 14px;
  font-family: ${fontRobotoCondensed};
  line-height: 16px;
  white-space: nowrap;
  text-align: center;
  vertical-align: middle;

  ${props => props.theme.breakpoints.up("tablet")} {
    margin-block-start: 4px;

    font-size: 12px;
    line-height: 14px;
  }
`;

export const StyledAnonymousAvatar = styled(IconAvatar, { ...transientOptions })<AvatarProps>`
  ${props =>
    css`
      display: ${props.$isLoggedIn ? "block" : "none"};
      ${props.theme.breakpoints.up("tablet")} {
        display: block;
      }
    `}
`;
